<template>
  <div>
    <a-modal v-model="openmodal" title="add sensor" width="75%" :maskClosable="false">
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :data-source="data"
      >
      </a-table>

      <template slot="footer">
        <a-button class="cancel" @click="handleClose">{{ $t('通用.按钮.取消') }}</a-button>
        <a-button class="save" @click="handleOk">{{ $t('通用.按钮.保存') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { pagePcba } from '@/api/pcba/pcba'
export default {
  data() {
    return {
      openmodal: false,
      selectedRowKeys: [],
      idList: [],
      columns: [
        {
          title: this.$t('临床医生.序列号'),
          dataIndex: 'serialNumber',
          key: 'serialNumber',
        },
        {
          title: this.$t('临床医生.针ID'),
          dataIndex: 'fixtureId',
          key: 'fixtureId',
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   key: 'createTime',
        // },
        // {
        //   title: '开始时间',
        //   dataIndex: 'startDate',
        //   key: 'startDate',
        // },
        // {
        //   title: 'batteryV',
        //   dataIndex: 'batteryV',
        //   key: 'batteryV',
        // },
        // {
        //   title: 'biasVHigh',
        //   dataIndex: 'biasVHigh',
        //   key: 'biasVHigh',
        // },
        // {
        //   title: 'remark',
        //   dataIndex: 'remark',
        //   key: 'remark',
        // },
      ],
      data: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      pagePcba({
        serialNumber: undefined,
        fixtureId: undefined,
        startDate: undefined,
        biasVHigh: undefined,
        biasVLow: undefined,
        ciHigh: undefined,
        ciLow: undefined,
        ciDraw: undefined,
        batteryV: undefined,
        cpOn: undefined,
        pageNum: 1,
        pageSize: 10,
      }).then((e) => {
        // console.log('pcba', e)
        this.data = e.data.records
      })
    },
    handleOk() {
      this.openmodal = false
      this.$emit('Save', this.idList)
    },
    handleOpenModal() {
      this.openmodal = true
    },
    handleClose() {
      this.openmodal = false
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys

      this.selectedRowKeys.forEach((e) => {
        this.idList = []
        this.idList.push(Number(this.data[e].id))
      })
    },
  },
}
</script>

<style scoped lang="less">
.cancel {
  border: none !important;
  color: #7f62ad;
  font-weight: 500;
  font-size: 16px;
}

.save {
  background-color: #7f62ad;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
}
</style>